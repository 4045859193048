
import { createRouter, createWebHistory } from 'vue-router'
import { authGuard} from '@auth0/auth0-vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/components/SplashPage.vue')
	},
	{
		path: '/Dashboard',
		name: 'Dashboards',
		component: () => import('../components/UserDashboard.vue'),
		beforeEnter: authGuard
	},
	{
		path:'/Map',
		name:'Map',
		component: () => import ("../components/MapComponent.vue")
	},

	{
		path: '/Jobs',
		name: 'Jobs',
		component: () => import('@/components/Jobs/JobsTable'),
		meta: { permission: "read:jobs" },
		beforeEnter: authGuard
	},
	{
		path: '/Calls',
		name: 'Calls',
		component: () => import('@/components/Calls/CallsTable.vue'),
		meta: { permission: "read:jobs" },
		beforeEnter: authGuard
	},
	{
		path: '/Customers',
		name: 'Customers',
		component: () => import('@/components/Customers/CustomersTable.vue'),
		meta: { permission: "read:customers" },
		beforeEnter: authGuard,
	},
	{
		path: '/Advertising',
		name: 'Advertising',
		component: () => import('@/components/Advertising/AdvertisingTable.vue'),
		meta: { permission: 'read:customers'},
		beforeEnter: authGuard
	},
	{
		path: '/Employees',
		name: 'Employees',
		component: () => import('../components/Employees/EmployeesTable.vue'),
		meta: { permission: "read:employees" },
		beforeEnter: authGuard
	},
	{
		path: '/Notes',
		name: 'Notes',
		component: () => import('../components/Notes/NotesTableViewer.vue'),
		meta: {permission: 'read:jobs'},
		beforeEnter: authGuard
	},
	{
		path: '/Invoices',
		name: 'Invoices',
		component: () => import('../components/Billing/InvoiceTable.vue'),
		meta: {permission: 'read:jobs'},
		beforeEnter: authGuard
	},
	{
		path: '/Payments',
		name: 'Payments',
		component: () => import('../components/Billing/PaymentTable.vue'),
		meta: {permission: 'read:jobs'},
		beforeEnter: authGuard
	},
	{
		path: '/Resources',
		name: 'Resources',
		component: () => import('../components/Resources/UserResources.vue'),
		beforeEnter: authGuard
	},
	{
		path:'/Resources/:resource',
		name: 'ViewResource',
		component: () => import('../components/Resources/ViewResource.vue'),
		beforeEnter: authGuard
	},
	// {
	// 	path: '/Utilities',
	// 	name: 'Utilities',
	// 	component: ()=> import('../components/Utilities/UtilitiesDownloads.vue'),
	// 	meta: { permission: 'download:utilities'},
	// 	beforeEnter: authGuard
	// },
	{
		path: '/Users',
		name: 'Users',
		component: () => import('@/components/Admin/Users/UsersTable.vue'),
		meta: { permission: "admin" },
		beforeEnter: authGuard
	},
	{
		path: '/Messages',
		name: 'Messages',
		component: () => import('@/components/Admin/Messages/MessagesTable.vue'),
		meta: { permission: "admin" },
		beforeEnter: authGuard
	},
	{
		path: '/CVT/Create',
		name: 'CVTCreator',
		component: () => import('@/components/Utilities/CVTFileCreator.vue'),
		meta: { permission: "edit:cvt" },
		beforeEnter: authGuard
	},
	{
		path: '/CVT/Download',
		name: 'CVTDownload',
		component: () => import('@/components/Utilities/CVTFileDownloader.vue'),
		meta: { permission: "edit:cvt" },
		beforeEnter: authGuard
	},
	{
		path: '/TestPage',
		name: 'TestPage',
		component: () => import('../components/TestPage.vue'),
		meta: { permission: "testing" },
		beforeEnter: authGuard
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/',
	}

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes
})



export default router;
