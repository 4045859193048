import { ref } from 'vue';
import { Modal } from 'bootstrap';



const modalManager =  {
    modalManagerEventTarget: new EventTarget(),
    savedModals: ref([]),
    openModals: [],
    saveCloseAllModals: function(){
        // Find all visible modals
        const visibleModals = Array.from(document.querySelectorAll('.modal.show'));
        visibleModals.forEach((modalElement) => {
            console.log(modalElement)
            this.savedModals.value.push(modalElement)
            this.savedModals.value.push(null)
            const modalInstance = Modal.getInstance(modalElement);
            modalInstance.hide();
        })
    },
    saveAndCloseModal: function(modal){
        this.savedModals.value.push(modal)
        this.savedModals.value.push(null)
        let modalInstance = Modal.getOrCreateInstance(modal)
        modalInstance.hide()
    },

    restoreModal: function (){
        this.openModals.pop()
        const modal = this.savedModals.value.pop()
        if(modal){
            Modal.getOrCreateInstance(modal).show();
        }else if(modal === undefined){
            this.modalManagerEventTarget.dispatchEvent(new CustomEvent('all-modals-closed'))
        }
    },

    modalOpened: function(modal) {
        // if(this.openModals.length > 0){
        //     this.savedModals.value.push(this.openModals[this.openModals.length - 1])
        // }
        this.openModals.push(modal)
    },

    handleBackButton: function() {
        if(this.openModals.length > 0){
            const topModal = this.openModals[this.openModals.length-1]
            Modal.getOrCreateInstance(topModal).hide()
            return false
        }
        return true
    }
};


export default modalManager;
